<template>
  <div>
    <div class="title flexB">
      <h1>{{ $t("top-select-user-confirm-manager") }}</h1>
      <!--      <div>
              &lt;!&ndash;        <span
              >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
              >&ndash;&gt;
              <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
            </div>-->
    </div>
    <div class="contents userMgt">
      <div class="box one filter">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="name">{{ $t("user-data-name") }}</option>
            <option value="userId">{{ $t("user-data-num") }}</option>
            <option value="loginId">{{ $t("user-data-email-id") }}</option>
            <option value="department">{{ $t("user-data-dept-name") }}</option>
            <option value="phone">{{ $t("user-data-phone-num") }}</option>
          </select>
          <input type="text" v-model="keyword" @keyup.enter="search" />
          <div class="buttonWrap" style="display: flex; margin-top: 0;">
            <button class="point medium" @click="search" style="margin-left: 20px; width: 120px;">{{ $t("btn-searching") }}</button>
            <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
          </div>
        </div>
        <div class="flexC">
          <p class="bold">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }}
          <span class="blue">{{ total }}</span
          >{{ $t("data-case-ea") }}
        </h2>
        <table>
          <tr>
            <th>{{ $t("user-data-num") }}</th>
            <th>{{ $t("user-data-name") }}</th>
            <th>{{ $t("user-data-email-id") }}</th>
            <th>{{ $t("user-data-dept-name") }}</th>
            <th>{{ $t("user-data-phone-num") }}</th>
            <th>{{ $t("user-data-authority") }}</th>
            <th>{{ $t("user-data-status") }}</th>
            <th>{{ $t("user-data-regdate") }}</th>
            <th>{{ $t("user-data-confirm")}}</th>
          </tr>
          <colgroup>
            <col style="width: 10%;"/>
            <col style="width: 15%;"/>
            <col style="width: 15%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
          </colgroup>
          <tr v-if="total == 0">
            <td colspan="9" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(data, i) in userList" :key="i">
            <td>{{ data.userId }}</td>
            <td>
              <a class="link">{{
                  data.name
                }}</a>
            </td>
            <td>{{ data.loginId }}</td>
            <td>
              {{ data.departmentInfo.name }}
            </td>
            <td>{{ getMask(data.phone) }}</td>
            <td>
              {{
                data.userType == "User"
                    ? $t("user-data-type-user")
                    : data.userType == "Admin"
                        ? $t("user-data-type-admin")
                            : "-"
              }}
            </td>
            <td>
              {{
                data.status == "Used"
                    ? $t("user-data-status-used")
                    : data.status == "Waiting"
                        ? $t("user-data-status-waiting")
                        : data.status == "NotUsed"
                            ? $t("user-data-status-not-used")
                            : data.status == "Inactive"
                                ? $t("user-data-status-inactivate")
                                : data.status == "Unsubscribe"
                                    ? $t("user-data-status-withdrawal")
                                    : ""
              }}
            </td>
            <td>
              {{ moment(data.registerDate).format("YYYY.MM.DD HH:mm:ss") }}
            </td>
            <td>
              <button class="small" @click="handleConfirm(data.userId, data)"
                      v-if="data.status == 'Waiting'" style="margin: auto 20px;">{{ $t("user-data-confirm-submit")}}</button>
              <span v-else > - </span>
            </td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              :currentPage = "currentPage + 1"
              @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchUserList , updateUser} from "@/api/user";
import { format } from "@/mixins/format";
export default {
  components: { Datepicker },
  mixins: [format],
  name: "UserList",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      userList: [],
      total: 0,
      keyword: null,
      keywordType: "all",
      statuses: "Waiting,Used",
      userTypes: "User,Manager",
      currentPage: 0,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 10, sub: 0 });
    this.preset();
  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  methods: {
    //회원승인
    handleConfirm(id, selectData) {
      selectData.status = "Used";
      updateUser(id, selectData).then((res) => {
        if (res.data.result == 0) {
          alert(this.$t("alert-message-check-user-info-upload"));
          //this.$router.go(-1);
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    preset(){
      var localStorageData = localStorage.getItem("user_searchData");
      if(localStorageData != undefined) {
        var searchData = JSON.parse(localStorageData);
        this.keyword = searchData.keyword;
        this.keywordType = searchData.keywordType;
        //this.userTypes = searchData.userTypes;
        //this.statuses = searchData.statuses;
        this.currentPage = searchData.pageNumber;
        this.startYmd =  searchData["dateRange.from"];
        this.endYmd =  searchData["dateRange.to"];
      }
    },
    persist(params){
      localStorage.setItem("user_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
    },
    search() {
      if (this.startYmd != null) {
        if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getUserList();
    },
    reset() {
      this.keywordType = "all";
      this.keyword = null;
      this.userTypes  = "User,Manager";
      this.statuses = "Waiting,Used";
      this.startYmd = null;
      this.endYmd = null;
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleCurrentChange(val) {
      this.currentPage = val  - 1;
      this.getUserList();
    },
    getUserList() {

      let params = {
        pageNumber: this.currentPage, // pagination
        pageSize: 10, // pagination
        keywordType: this.keywordType,
        keyword: this.keyword,
        userTypes: this.userTypes,
        statuses: this.statuses,
      };
      params["dateRange.from"] = null;
      params["dateRange.to"] = null;

      if (this.startYmd != null&& this.endYmd != null) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["dateRange.from"] = from;
        params["dateRange.to"] = to;
      }

      this.persist(params);
      fetchUserList(params).then((res) => {
        if (res.data.result == 0) {
          this.total = res.data.data.total;
          this.userList = res.data.data.content;

        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleRefresh() {
      this.reset();
      this.callFunctionsWhenMounted();
    },
    callFunctionsWhenMounted(){
      this.getUserList();
    }
  },
};
</script>
